import React, { useState } from 'react';
import { Modal, Button, Tooltip } from 'antd';
import {DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const DeleteModal = ({handleOk, disabled, title, style, size = 'middle', shape = 'default', question}) => {
    const {t} = useTranslation();

    const [visible, setVisible] = useState(false);

    const handle = (visible) => {
        setVisible(visible);
    };

    return (
        <>
            <Tooltip title={title}>
                <Button shape={shape}
                        style={style}
                        size={size}
                        disabled={disabled} 
                        onClick={() => handle(true)} 
                        icon={<DeleteOutlined/>}/>
            </Tooltip>
            <Modal
                title={t('common.deletion')}
                width={800}
                open={visible}
                onCancel={() => handle(false)}
                footer={[
                    <div className={"submit-button-block"}>
                        <Button
                            onClick={() => handle(false)}>
                            {t(`common.cancel`)}
                        </Button>
                        <Button onClick={() => {
                                    handleOk();
                                    handle(false);
                                }}
                                type="primary">
                            {t(`common.delete`)}
                        </Button>
                    </div>
                ]}>
                    <p> <ExclamationCircleOutlined /> {question || t(`questions.delete`)}</p>
            </Modal>
        </>
    )
};

export default DeleteModal;
