import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import React, {useEffect, useRef, useState} from 'react';
import { isDeepEqual } from '../../utils/isDeepEqual';
import { custom_fetch } from '../../utils/fetchServices';
import dayjs from 'dayjs';
import { show_error } from '../../utils/showError';

const NewTableForm = ({tableModeSwitch, getTables, handleChanged, selectTableHandler, tablesList, handleClose, logOut}) => {
    const inputRef = useRef();

    const {t} = useTranslation();
       
    const [form] = Form.useForm();

    const isFieldTouched = Form.useWatch([], form);

    const formValues = form.getFieldsValue();

    const [fileName, setFileName] = useState();
    const [schema, setSchema] = useState();

    const getTemplate = () => {
        custom_fetch(
            `api/new/schema/get_template`, 
            'GET',
            (data) => {
                const ifJson = () => {
                    try {
                        JSON.parse(data);
                    } catch (e) {
                        return false;
                    };

                    return JSON.parse(data);
                };

                const receivedSchema = ifJson();

                for (const property in receivedSchema.properties) {
                    receivedSchema.properties[property] = {...receivedSchema.properties[property], id: property + '_' + dayjs().unix()};
                };

                setSchema(receivedSchema);
            },
            () => {},
            () => logOut()
        );
    };

    useEffect(() => {
        getTemplate();
    }, []);

    const handleFileChange = (e) => {
        if (!!e.target.files?.length) {
            const reader = new FileReader();
            const fileName = e.target.files[0].name;
            
            reader.readAsText(e.target.files[0]);
            reader.onload = e => {
                try { 
                    setSchema(JSON.parse(e.target.result));
                    setFileName(fileName);
                } catch(e) { 
                    show_error(t('errors.wrong_schema'));
                }
            };
        };
    };
    
    const handleOk = (tableName, tableSchema) => {
        custom_fetch(
            `api/${tableName}/create`, 
            'GET',
            () => {
                custom_fetch(
                    `api/${tableName}/schema/set`, 
                    'POST',
                    async () => {
                        selectTableHandler(tableName);
                        
                        handleClose();
                
                        tableModeSwitch(true);

                        getTables();
                    },
                    () => {
                        custom_fetch(
                            `api/${tableName}/delete`, 
                            'GET',
                            () => {
                                handleClose();

                                getTables();
                            },
                            () => {},
                            () => logOut()
                        );
                    },
                    () => logOut(),
                    JSON.stringify({schema: tableSchema}),
                    true
                );
            },
            () => {}
        );
    };

    useEffect(() => {
        if (!isDeepEqual({}, formValues)) {
            handleChanged(true);
        } else {
            handleChanged(false);
        };
        // eslint-disable-next-line
    }, [isFieldTouched]);

    useEffect(() => {
        inputRef.current.focus();
    }, []);
    
    return (
        <Form form={form}
              id='form'
              layout='vertical' 
              style={{marginTop: 20}}
              onFinish={() => handleOk(form.getFieldValue('table_name'), schema)} 
              autoComplete="off">
            <Form.Item  required
                        name={'table_name'}
                        className='control_class' 
                        label={t(`table_create.table_title`)} 
                        rules={
                            [{   
                                pattern: new RegExp(/^[a-zA-Z0-9_]*$/), 
                                message: t(`errors.only_latin_numbers`)},
                            {   required: true,
                                message: t(`errors.field_required`)
                            },
                            {   message: t(`errors.already_exists`),
                                validator: (_, value) => {
                                    if (value) {
                                        if (!tablesList.includes(value.toLowerCase())) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(t(`errors.already_exists`));
                                        }
                                    }
                                }
                            }]
                        }>
                <Input ref={inputRef} style={{width: "100%"}}/>
            </Form.Item>
            <Form.Item>
                <div>
                    <input name="file_json_schema"
                        id="file_json_schema"
                        type="file"
                        style={{display: 'none'}}
                        onChange={handleFileChange}
                        onClick={e => e.target.value = null}
                        accept={'.json'}/>
                    <label style={{cursor: "pointer"}} htmlFor="file_json_schema">
                        <></>
                        <Button style={{marginRight: 10, pointerEvents: "none"}} type="primary">
                            {t(`table_create.upload_schema`)}
                        </Button>
                    </label>
                    {fileName}
                </div>
            </Form.Item>
        </Form>
    )
};

export default NewTableForm;