import { show_error } from "./showError";

export const custom_fetch = async (url, method, on_success, on_failure, logOutFn, body, cors, errorDisplay = true, isUpload = false) => {
    const sessionStorage_headers = sessionStorage.getItem('headers')? JSON.parse(sessionStorage.getItem('headers')): {};
    const defaultHeaders = !isUpload? 
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }:
        {
        };

    await fetch(`${process.env.PUBLIC_URL}/${url}`, cors? {
        method,
        mode: 'cors',
        headers: new Headers({...sessionStorage_headers, ...defaultHeaders}),
        body
    }: {
        method,
        body,
        headers: new Headers({...sessionStorage_headers, ...defaultHeaders})
    })
    .then(response => {
        if (response.status === 200 || response.status === 204 || response.status === 302) {
            const responseHeadersArr = [...response.headers].filter(([header, val]) => header.toLowerCase().includes('x-cms')) || [];

            if (responseHeadersArr.length) {
                let headers = sessionStorage_headers;

                responseHeadersArr.forEach(([header, val]) => {
                    if (!Object.values(headers).includes(header)) headers = {...headers, [header]: val}
                });
                
                sessionStorage.setItem('headers', JSON.stringify(headers));
            };

            return response.text()
        } else {
            return JSON.stringify({error: `${response.status} ${response.statusText}`})
        }
    })
    .then(async (text) => {
        const data = url !== 'oauth_logout' && text? JSON.parse(text): null;
        
        if (!data?.error || data?.error === 'Success') {
            await on_success(data);
        } else if (data?.error === '401 Unauthorized' && logOutFn()) {
            errorDisplay && logOutFn();
        } else {
            errorDisplay && show_error(data.error);

            on_failure();
        }
    })
    .catch(error => errorDisplay && show_error(error))
}

export const get_blob = (url, on_success, on_failure, logOutFn, errorDisplay = true) => {
    const sessionStorage_headers = sessionStorage.getItem('headers')? JSON.parse(sessionStorage.getItem('headers')): {};

    fetch(`${process.env.PUBLIC_URL}/${url}`, {
        method: "GET",
        headers: new Headers(sessionStorage_headers)
    })
    .then(response => {
        if (response.ok) {
            const responseHeadersArr = [...response.headers].filter(([header, val]) => header.toLowerCase().includes('x-cms')) || [];

            if (responseHeadersArr.length) {
                let headers = sessionStorage_headers;

                responseHeadersArr.forEach(([header, val]) => {
                    if (!Object.values(headers).includes(header)) headers = {...headers, [header]: val}
                });
                
                sessionStorage.setItem('headers', JSON.stringify(headers));
            };
            
            return response.blob();
        } else {
            errorDisplay && show_error({error: `${response.status} ${response.statusText}`})
        };
    })
    .then(data => {
        if (data) {
            on_success(data);
        } else if (data?.error === '401 Unauthorized' && logOutFn()) {
            errorDisplay && show_error(data.error);

            logOutFn();
        } else {
            errorDisplay && show_error(data.error);
            
            on_failure();
        }
    })
    .catch(error => {
        if (error) {
            show_error(error);
            
            on_failure();
        }
    })
}

export const download_file = (url, name, type, format, logOutFn, errorDisplay = true) => {
    const sessionStorage_headers = sessionStorage.getItem('headers')? JSON.parse(sessionStorage.getItem('headers')): {};
    
    fetch(`${process.env.PUBLIC_URL}/${url}`, {
            method: "GET",
            headers: new Headers(sessionStorage_headers)
        })
        .then(response => {
            if (response.ok) {
                const responseHeadersArr = [...response.headers].filter(([header, val]) => header.toLowerCase().includes('x-cms')) || [];

                if (responseHeadersArr.length) {
                    let headers = sessionStorage_headers;
    
                    responseHeadersArr.forEach(([header, val]) => {
                        if (!Object.values(headers).includes(header)) headers = {...headers, [header]: val}
                    });
                    
                    sessionStorage.setItem('headers', JSON.stringify(headers));
                };

                return response.blob();
            } else if (response.status === 401) {
                logOutFn();
            } else {
                errorDisplay && show_error(JSON.stringify({error: `${response.status} ${response.statusText}`}));
            }
        })
        .then(data => {
            const url = window.URL.createObjectURL(data);
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute(
                'download',
                `${name}-${type}.${format}`,
            );
        
            document.body.appendChild(link);
        
            link.click();
        
            link.parentNode.removeChild(link);
        });
};