import React, { useState } from 'react';
import { Modal, Button, Tooltip } from 'antd';
import { EditOutlined, ExclamationCircleOutlined, PlusOutlined, CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const CreateEditModal = ({mode, tooltip, children, buttonStyle = {}, size = 'middle', shape = 'default', bodyHeight = 800, disabled = false}) => {
    const {t} = useTranslation();

    const [visible, setVisible] = useState({
        formModal: false,
        confirmModal: false
    });
    const [isChanged, setIsChanged] = useState(false);

    const handleFormModal = (v) => {
        if (isChanged && !v) {
            handleConfirmModal(true)
        } else {
            setVisible({...visible, formModal: v});
        };
    };

    const handleConfirmModal = (v) => {
        setVisible({...visible, confirmModal: v});
    };

    const handleChanged = (isChanged) => {
        setIsChanged(isChanged);
    };

    const closeAll = () => {
        setVisible({formModal: false, confirmModal: false});
    };

    return (
        <>
            <Tooltip title={tooltip}>
                <Button shape={shape}
                        style={buttonStyle}
                        size={size}
                        disabled={disabled}
                        icon={mode === 'editing'? <EditOutlined/>: mode === 'adding'? <PlusOutlined/>: <CopyOutlined/>}
                        onClick={() => handleFormModal(true)}/>
            </Tooltip>
            <Modal  footer={[
                        <div key={'submit-button-block'} className={'submit-button-block'}>
                            <Button key={'cancel_button'} onClick={() => handleFormModal(false)}>
                                {t(`common.cancel`)}
                            </Button>
                            <Button htmlType="submit"
                                    key={'ok_button'}
                                    type="primary"
                                    form='form'>
                                        {t(`common.ok`)}
                            </Button>
                        </div>
                    ]}
                    width={800}
                    open={visible.formModal}
                    title={t(`common.${mode}`)}
                    destroyOnClose
                    onCancel={() => handleFormModal(false)}>
                <Modal  title={t('questions.sure')}
                        width={800}
                        style={{height: '300px !important'}}
                        open={visible.confirmModal}
                        onCancel={() => handleConfirmModal(false)}
                        destroyOnClose
                        footer={null}>
                    <p> <ExclamationCircleOutlined /> {t(`questions.will_not_save`)}</p>
                    <div className={'submit-button-block'}>
                        <Button
                            onClick={() => handleConfirmModal(false)}>
                            {t(`common.cancel`)}
                        </Button>
                        <Button
                            onClick={closeAll}
                            type='primary'>
                            {t(`common.ok`)}
                        </Button>
                    </div>
                </Modal>
                {children && React.cloneElement(children, {handleChanged, mode, isChanged, handleClose: () => closeAll()})}
            </Modal>
        </>
    )
};

export default CreateEditModal;
