// eslint-disable-next-line
import React, { useEffect, useRef, useState } from 'react';
import { Input, Button, Select, DatePicker, Typography } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const {Option} = Select;
const {Text} = Typography;

const CustomArray = ({ value, onChange, type, options }) => {
    const ref = useRef();

    const [newItem, setNewItem] = useState();
    const [currentIndex, setCurrentIndex] = useState();

    useEffect(() => {
        if (currentIndex || currentIndex === 0) {
            ref.current?.focus();
        };
    }, [currentIndex, JSON.stringify(value)]);

    const onNewItemAdd = (val) => {
        if (newItem || val) {
            onChange([...(value || []), newItem || val || '']);
            setNewItem();
        };
    };

    const onItemChange = (val, i) => {
        const newValue = [...value];

        newValue[i] = val;

        setCurrentIndex(i);

        onChange([...(newValue || [])]);
    };
    
    return (
        <div style={{ width: '100%' }}>
            {(value || []).map((item, index) => 
                <div key={item + index} style={{display: 'flex', marginBottom: 10}}>
                    {type === 'boolean' && <Select key={item + index + '_input'} onChange={val => onItemChange(val, index)} defaultValue={item} options={['true', 'false'].map(item => ({value: item, label: item}))} className='cui'/>}
                    {type === 'enum' && <Select key={item + index + '_input'} onChange={val => onItemChange(val, index)} defaultValue={item} options={options.map(item => ({value: item, label: item}))} className='cui'/>}
                    {type === 'date-time' && <DatePicker showNow={false} allowClear={false} defaultValue={item} onOk={val => onItemChange(val, index)} key={item + index + '_input'} className='cui' showTime/>}
                    {type === 'reference' && 
                        <Select showSearch
                                optionLabelProp="children"
                                onChange={val => onItemChange(val, index)}
                                defaultValue={item} 
                                key={item + index + '_input'}
                                className='cui'
                                autoClearSearchValue
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children[option.children.length - 1]?.toLowerCase().includes(input.toLowerCase()) || option.children[0].props.children.toLowerCase().includes(input.toLowerCase())
                                }>
                                    {options.map(item => <Option value={item.id} key={item.id}><Text code>{item.id}</Text>{item.value}</Option>)}
                        </Select>}
                    {type === 'string' && <Input    key={item + index + '_input'}
                                                    ref={currentIndex === index && ref || null}
                                                    onChange={(e) => onItemChange(e.target.value, index)}
                                                    className='cui'
                                                    defaultValue={item}/>}
                    {type === 'number' && <Input ref={currentIndex === index && ref || null} key={item + index + '_input'} onChange={(e) => onItemChange(e.target.value, index)} type='number' className='cui' defaultValue={item}/>}
                    <Button key={item + index + '_button'} className='cub' icon={<DeleteOutlined />} onClick={() => {
                        const newArr = [...value];

                        newArr.splice(index, 1);

                        onChange(newArr);
                    }}/>
                </div>
            )}
            {type === 'boolean' && <Select value={newItem} onChange={val => setNewItem(val)} onBlur={() => onNewItemAdd()} options={['true', 'false'].map(item => ({value: item, label: item}))} className='cui'/>}
            {type === 'enum' && <Select value={newItem} onChange={val => setNewItem(val)} onBlur={() => onNewItemAdd()} options={options.map(item => ({value: item, label: item}))} className='cui'/>}
            {type === 'date-time' && <DatePicker showNow={false} allowClear={false} value={null} onOk={val => onNewItemAdd(val)} className='cui' showTime/>}
            {type === 'reference' && 
                <Select showSearch
                        optionLabelProp="children"
                        value={newItem}
                        onChange={value => setNewItem(value)}
                        onBlur={() => onNewItemAdd()}
                        className='cui'
                        autoClearSearchValue
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children[option.children.length - 1]?.toLowerCase().includes(input.toLowerCase()) || option.children[0].props.children.toLowerCase().includes(input.toLowerCase())
                        }>
                            {options.map(item => <Option value={item.id} key={item.id}><Text code>{item.id}</Text>{item.value}</Option>)}
                </Select>}
            {type === 'string' && <Input    className='cui' 
                                            value={newItem} 
                                            onChange={e => setNewItem(e.target.value)} 
                                            onBlur={() => onNewItemAdd()}/>}
            {type === 'number' && <Input className='cui' value={newItem} type='number' onChange={e => setNewItem(e.target.value)} onBlur={(e) => onNewItemAdd(e.target.value)}/>}
            <Button className='cub' icon={<PlusOutlined/>} onClick={() => newItem && onNewItemAdd}/>
        </div>
    );
}

export default CustomArray;