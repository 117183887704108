export const orderedProperties = (schema, isSchemaTable) => {
    const properties = {...schema?.properties} || {};
    const newData = Array(Object.entries(properties).length);

    if (Object.entries(properties).length) {
        const data = !isSchemaTable? Object.entries(properties): Object.entries(properties).map(item => ({...item[1], content: item[0]}));

        for (const item of data) {
            newData.splice((!isSchemaTable? item[1]: item).position - 1, 1, (!isSchemaTable? [item[0], item[1]]: item))
        };
    };
        
    return newData || [];
};