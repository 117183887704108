export const isDeepEqual = (object1 = {}, object2 = {}) => {
    const isObject = (object) => object && !Array.isArray(object) && typeof object === "object";
    const isArray = (array) =>  array && Array.isArray(array);

    const areArraysEqual = (array1, array2) => {
        return array1.length === array2.length && array1.every((element, index) => element === array2[index]);
    };

    const check = (keys) => {
        for (const key of keys) {
            const value1 = object1[key] || object1[key] === 0? object1[key]: null;
            const value2 = object2[key] || object2[key] === 0? object2[key]: null;

            if ((!value1 || !value2) &&
                ((!!(isArray(value1) && value1.length && !value2) ||
                !!(isArray(value2) && value2.length && !value1)) ||
                (!!(isObject(value1) && Object.entries(value1).length && !value2) ||
                !!(isObject(value2) && Object.entries(value2).length && !value1) ||
                !!(value1 === 0 && !value2 && (value2 !== 0 || value2 !== false)) || !!(value2 === 0 && !value1 && (value1 !== 0 || value2 !== false)))
            )) return false;

            const areObjects = isObject(value1) && isObject(value2);
            const areArrays = isArray(value1) && isArray(value2);
            
            if ((areObjects && !isDeepEqual(value1, value2)) ||
               (areArrays && !areArraysEqual(value1, value2)) ||
                  (!areObjects && !areArrays && !isObject(value1) && !isObject(value2) && !isArray(value1) && !isArray(value2) && value1 !== value2)) return false;
          };

        return true;
    };

    if (!check(Object.keys(object1)) || !check(Object.keys(object2))) return false;

    return true;
};