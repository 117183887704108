import React from 'react';
import {Button, Dropdown, Tooltip} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { download_file } from '../utils/fetchServices';

const ExportFile = ({type, item, style, title, logOut, format}) => {
    const {t} = useTranslation();

    const downloadFile = (format) => download_file(
                                        `api/${item}/${type}/export?${new URLSearchParams({format})}`,
                                        item,
                                        type,
                                        format,
                                        logOut
                                    );

    const formats = [{key: 'json', label: 'json'}, {key: 'csv', label: 'csv', disabled: type === 'schema'}];

    return (
        <Dropdown menu={{
                            items: formats,
                            onClick: (item) => downloadFile(item.key)
                        }}
                  placement="bottomLeft">
            <Button id='download'
                    style={style}
                    size='large'
                    icon={<DownloadOutlined />}>
            </Button>
        </Dropdown>
    )
};

export default ExportFile;