export const translation = {
  commonRu: {
    common: {
      login: "Войти",
      new: "Добавить",
      new_table: "Добавить новую таблицу",
      delete_data_table: "Удалить данные таблицы",
      delete_table: "Удалить таблицу",
      download_schema: "Скачать схему таблицы",
      download_data: "Скачать данные таблицы",
      edit: "Редактировать",
      ok: "Ок",
      cancel: "Отмена",
      delete: "Удалить",
      edit_mode: "Режим редактирования",
      select: "Выбрать",
      logout: "Выйти",
      search: "Искать",
      reset: "Сброс",
      download: "Скачать",
      deletion: "Удаление",
      adding: "Добавление",
      editing: "Редактирование",
      lang: "Язык",
      upload: "Загрузить файл",
      clone: "Клонировать",
      cloning: "Клонирование"
    },
    table: {
      actions: "Действия"
    },
    form: {
      add: "Добавить элемент"
    },
    table_create: {
      table_name: "Имя таблицы",
      required: "Обязательное поле",
      filled_by_server: "Заполняется на сервере",
      uid: "UID",
      content: "Имя",
      table_title: "Имя таблицы",
      title: "Заголовок",
      from: "С",
      to: "По",
      title1: "Заголовок поля (из)",
      title2: "Заголовок поля (в)",
      data1: "Имя поля данных (из)",
      data2: "Имя поля данных (в)",
      description: "Описание",
      pattern: "Паттерн",
      format: "Формат",
      reference: "Референс",
      key: "Ключ",
      value: "Значение",
      type: "Тип",
      display_properties: "Свойства отображения",
      enum: "Словарь значений",
      server_side: "Заполняется на сервере",
      upload_schema: "Загрузить схему",
      upload_data: "Загрузить данные",
      array: "Массив"
    },
    questions: {
      delete: "Вы уверены, что хотите удалить элемент?",
      delete_data_table: "Вы уверены, что хотите удалить данные таблицы?",
      delete_table: "Вы уверены, что хотите удалить таблицу?",
      delete_table: "Вы хотите удалить эту таблицу?",
      will_not_save: "Элемент не будет сохранен. Вы уверены?",
      table_data_will_be_rewrited: "Данные таблицы перезапишутся. Вы уверены?",
      sure: "Уверены?",
    },
    errors: {
      error: "Ошибка",
      wrong: "Что-то пошло не так",
      only_latin_numbers: "Только латиница и цифры",
      already_exists: "Уже существует",
      value_already_exists: "Одинаковые значения",
      login: "Неверный логин или пароль",
      auth_server: "Нет сервера аутентификации",
      field_required: "Обязательное поле",
      less: "Значение '{{arg1}}' не меньше значения '{{arg2}}'",
      no_audio: "Нет аудио",
      file_req: "Файл является обязательным",
      only_numbers: "Только цифры",
      only_integer: "Только целые числа",
      inappropriate_name: "Недопустимое имя",
      wrong_schema: "Схема повреждена",
      incorrect_file: "Файл некорректен"
    }
  },
  commonEn: {
    common: {
      login: "Sign in",
      new: "Add",
      new_table: "Add New Table",
      delete_data_table: "Delete data table",
      delete_table: "Delete table",
      download_schema: "Download Table Schema",
      download_data: "Download Table Data",
      edit: "Edit",
      ok: "Ok",
      cancel: "Cancel",
      delete: "Delete",
      edit_mode: "Edit mode",
      select: "Select",
      logout: "Logout",
      search: "Search",
      reset: "Reset",
      download: "Download",
      deletion: "Deletion",
      adding: "Adding",
      editing: "Editing",
      lang: "Language",
      upload: "Upload file",
      clone: "Clone",
      cloning: "Cloning"
    },
    table: {
      actions: "Actions"
    },
    form: {
      add: "Add element"
    },
    table_create: {
      table_name: "Table name",
      required: "Is required",
      filled_by_server: "Filled by server",
      uid: "UID",
      table_title: "Table name",
      content: "Name",
      title: "Title",
      from: "From",
      to: "To",
      title1: "Title (from)",
      title2: "Title (to)",
      data1: "Data name (from)",
      data2: "Data name (to)",
      description: "Description",
      pattern: "Pattern",
      format: "Format",
      reference: "Reference",
      key: "Key",
      value: "Value",
      type: "Type",
      display_properties: "Display Properties",
      enum: "Enum",
      server_side: "Filled by server",
      upload_schema: "Upload schema",
      upload_data: "Upload data",
      array: "Array"
    },
    questions: {
      delete: "Are you sure you want to delete item?",
      delete_data_table: "Are you sure you want to delete data table?",
      delete_table: "Are you sure you want to delete table?",
      delete_table: "Delete this table?",
      will_not_save: "The item won't be saved. Are you sure?",
      table_data_will_be_rewrited: "Table data will be rewrited. Are you sure?",
      sure: "Sure?",
    },
    errors: {
      error: "Error",
      wrong: "Something went wrong",
      only_latin_numbers: "Only latin and numbers",
      already_exists: "Already exists",
      value_already_exists: "Equal values",
      login: "Wrong login or password",
      auth_server: "No auth server",
      field_required: "Is a required field",
      less: "'{{arg1}}' value is not less then '{{arg2}}' value",
      no_audio: "No audio",
      file_req: "File is required",
      only_numbers: "Only numbers",
      only_integer: "Only integer",
      inappropriate_name: "Inappropriate name",
      wrong_schema: "Schema is damaged",
      incorrect_file: "File is incorrect"
    }
  }
}