import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Select, Typography, DatePicker, Button, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { isDeepEqual } from '../../utils/isDeepEqual';
import dayjs from 'dayjs';
import { orderedProperties } from '../../utils/orderProperties';
import CustomArray from '../CustomFormItems/CustomArray';

const {TextArea} = Input;
const {Text} = Typography;
const {Option} = Select;

const {RangePicker} = DatePicker;

const DataForm = ({row, isChanged, handleChanged, handleClose, handleAddEditRow, schema, reference, addArrLength, mode}) => {
    const inputRef = useRef();

    const {t} = useTranslation();
    
    const [form] = Form.useForm();

    const isFieldTouched = Form.useWatch([], form);
    
    const [files, setFiles] = useState();

    const formValues = form.getFieldsValue();

    useEffect(() => {
        // inputRef.current.focus();
    }, []);

    const parsedRow = Object.fromEntries(Object.entries(row || {}).filter(([key, value]) => !schema.server_side?.includes(key)).map(([key, value]) => {
        if (schema.properties[key]?.format === 'date-time') return [key, value? dayjs.unix(Number(value)): undefined];
        if (schema.properties[key]?.format === 'period' || schema.properties[key]?.type === 'array' && schema.properties[key]?.items?.type === 'date-time') {return [key, value? value.map(item => dayjs.unix(Number(item))): undefined]};

        return [key, value];
    }));

    const filteredRow = Object.fromEntries(Object.entries(row || {}).filter(([key, value]) => !schema.server_side?.includes(key)));

    const parsedFormValues = Object.fromEntries(Object.entries(formValues || {}).filter(([key, value]) => !schema.server_side?.includes(key)).filter(([key, value]) => value === 0 || value === false? true: value || value?.length).map(([key, value]) => {
        if (schema.properties[key]?.format === 'date-time') return [key, value? dayjs(value).unix(): undefined];
        if (schema.properties[key]?.format === 'period' || schema.properties[key]?.type === 'array' && schema.properties[key]?.items?.type === 'date-time') return [key, value? value.map(item => dayjs(item).unix()): undefined];
        if (schema.properties[key]?.format === 'data' && Object.entries(files || {}).length) return [key, files?.[key]?.name];

        return [key, value];
    }));

    useEffect(() => {
        form.setFieldsValue(parsedRow);
    }, [row]);

    useEffect(() => {
        if (!isDeepEqual(filteredRow, parsedFormValues)) {
            handleChanged(true);
        } else {
            handleChanged(false);
        };
    }, [isFieldTouched]);

    useEffect(() => {
        Object.entries(files || {}).length && Object.entries(files).forEach(([key, value]) => form.setFieldValue(key, value?.name));
    }, [files]);

    // useEffect(() => {
    //     addArrLength(Object.entries(formValues).filter(([key, value]) => schema?.properties[key].type === 'array' && schema?.properties[key].format !== 'period')?.reduce((acc, [key, val]) => acc = acc + (val? val.length: 0), 0));
    // }, [formValues]);

    const handleOk = () => {
        if (isChanged || mode === 'cloning') {
            handleAddEditRow(parsedFormValues, mode === 'editing'? 'set': 'add', files, handleClose)
        } else {
            handleClose();
        };
    };
    
    return (
        <Form form={form} 
              layout='vertical' 
              id='form'
              style={{marginTop: 20}}
              onKeyDown={(e)=> e.keyCode == 13 && e.target.tagName !== 'TEXTAREA' && e.preventDefault()}
              onFinish={handleOk}
              autoComplete="off">
                {orderedProperties(schema, false).map(([key, value]) => {
                    const rules =   !value.server_side?.includes(key)? 
                                        [...(value.required ? 
                                            [{
                                                required: true,
                                                message: t(`errors.field_required`)
                                            }] : 
                                            []
                                        ),
                                        ...(value.pattern ? 
                                        [{
                                            pattern: value.pattern, 
                                            message: t(`errors.only_latin_numbers`)
                                        }] : 
                                        []
                                        ),
                                        ...(value.items?.pattern ? 
                                            [{
                                                validator: (_, val) => {
                                                    if (val || val?.length) {
                                                        for (const [index, item] of [...val].entries()) {
                                                            if (value.items?.pattern === '^[A-Za-z0-9_]+$') {
                                                                if (!/^[A-Za-z0-9_]+$/.test(item)) {
                                                                    return Promise.reject(t(`errors.only_latin_numbers`));
                                                                };
                                                            }
                                                        };
                                                    };

                                                    return Promise.resolve();
                                                }
                                            }] : 
                                            []
                                            ),
                                        ...(key === schema.uid?   
                                            [{
                                                validator: (_, val) => {
                                                    if (val && Object.keys(schema).filter(item => item !== row?.content).find(item => item.toLowerCase() == val.toLowerCase())) {
                                                        return Promise.reject(t(`errors.already_exists`));
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                }
                                            }] :
                                            []
                                        ),
                                        ...(value.type === 'array' && value.format !== 'period' ? 
                                            [{
                                                validator: (_, val) => {
                                                    if (val || val?.length) {
                                                        for (const [index, item] of [...val].entries()) {
                                                            if ([...val].some((el, i) => value.items?.type !== 'date-time'? i !== index && item === el: i !== index && dayjs(item).unix() === dayjs(el).unix())) {
                                                                return Promise.reject(t(`errors.value_already_exists`));
                                                            };
                                                        };
                                                    };

                                                    return Promise.resolve();
                                                }
                                            }] : 
                                            []
                                        )]:
                                        [];

                    return (
                        !schema?.server_side?.includes(key) && <Form.Item required={value.required}
                                    key={key}
                                    name={key}
                                    className='control_class'
                                    label={value.title}
                                    rules={rules}>
                            {(() => {
                                if (value.type === 'array') {
                                    if (value.format === 'period')
                                        return (
                                            <RangePicker style={{width: "100%"}}
                                                         showTime />
                                        );

                                    if (value.items?.type === 'enum') 
                                        return (
                                            <CustomArray type={'enum'} options={value.items.enum}/>
                                        );

                                    if (value.items?.type === 'reference')
                                        return (
                                            <CustomArray type={'reference'} options={Object.values(reference?.[key] || {})}/>
                                        );
                                    
                                    if (value.items?.type === 'date-time')
                                        return (
                                            <CustomArray type={'date-time'}/>
                                        );

                                    if (value.items?.type === 'boolean')
                                        return (
                                            <CustomArray type={'boolean'}/>
                                        );

                                    if (value.items?.type === 'number')
                                        return (
                                            <CustomArray type={'number'}/>
                                        );

                                    return (
                                        <CustomArray type={'string'}/>
                                    )
                                };

                                if (value.type === 'string') {
                                    if (value.display_properties?.form?.includes('textarea')) 
                                        return (
                                            <TextArea style={{ height: 96, width: "100%" }}
                                                      allowClear
                                                      className='control_class'/>
                                        );

                                    if (value.format === 'enum')
                                        return (
                                            <Select allowClear options={value.enum?.map(item => ({value: item, label: item}))} style={{ width: '100%' }}/>
                                        );
                        
                                    if (value.reference) 
                                        return (
                                            <Select showSearch
                                                    allowClear
                                                    style={{ width: '100%' }}
                                                    autoClearSearchValue
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children[option.children.length - 1]?.toLowerCase().includes(input?.toLowerCase()) || option.children[0].props.children.toLowerCase().includes(input?.toLowerCase())
                                                    }>
                                                        {Object.values(reference[key] || {}).map(item => <Option value={item.id} key={item.id}><Text code>{item.id}</Text>{item.value}</Option>)}
                                            </Select>
                                        )

                                    if (value.format === 'data') 
                                        return (
                                            <div>
                                                <input  name="file_wav_audio"
                                                        allowClear
                                                        style={{display: 'none'}}
                                                        id="file_wav_audio"
                                                        type="file"
                                                        onChange={(e) => {
                                                            if (e.target?.files) 
                                                                setFiles({...files, [key]: {name: e.target?.files[0].name, file: e.target?.files[0]}});
                                                        }}
                                                        onClick={e => e.target.value = null}
                                                        accept={'.wav'}
                                                />
                                                <label style={{cursor: "pointer"}} htmlFor="file_wav_audio">
                                                    <Button style={{marginRight: 10, pointerEvents: "none"}} type="primary">
                                                        {t(`common.upload`)}
                                                    </Button>
                                                </label>
                                                {files?.[key]?.name || formValues[key]}
                                            </div>
                                        )
                        
                                    return (
                                        <Input allowClear disabled={row && key == schema.uid && mode !== 'cloning'} ref={inputRef} style={{width: "100%"}}/>
                                    )
                                };

                                if (value.type === 'number') {
                                    if (value.format === 'date-time') 
                                        return (
                                            <DatePicker style={{width: "100%"}} showTime />
                                        )

                                    return (
                                        <InputNumber allowClear type="number" style={{width: "100%"}}/>
                                    )
                                };

                                if (value.type === 'boolean') {
                                    return (
                                        <Select allowClear options={[true, false].map(bool => ({value: bool, label: bool.toString()}))} style={{ width: '100%' }}/>
                                    )
                                };
                            })()}
                        </Form.Item>
                    )
                })}
        </Form>
    )
};

export default DataForm;