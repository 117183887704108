import React, {useEffect, useState} from 'react';
import { Table, Space, Tag } from 'antd';
import { custom_fetch } from '../../utils/fetchServices';
import { useTranslation } from 'react-i18next';
import DeleteModal from '../Modals/DeleteModal';
import CreateEditModal from '../Modals/CreateEditModal';
import SchemaForm from '../Forms/SchemaForm';
import { useSpring, animated } from '@react-spring/web';
import { MenuOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { orderedProperties } from '../../utils/orderProperties';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
  } from '@dnd-kit/sortable';
  import { CSS } from '@dnd-kit/utilities';

  const Row = ({ children, ...props }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props['data-row-key'],
    });
    
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
        },
      ),
      transition,
      ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999,
          }
        : {}),
    };

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child.key === 'sort') {
            return React.cloneElement(child, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: 'none',
                    cursor: 'move',
                  }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
};

const SchemaTable = ({accessList = {}, selectedTableName, tablesList, journal, logOut}) => {
    const [animatedRow, animatedRowApi] = useSpring(
        () => ({
            from: { opacity: 0, transform: "translateX(0%)" },
            config: {duration: 300}
        }), 
        []
    );
    
    const {t} = useTranslation();

    const [isLoaded, setIsLoaded] = useState(false);
    const [schema, setSchema] = useState();
    const [dataSource, setDataSource] = useState();

    const [page, setPage] = useState(Number(sessionStorage.getItem('currentPage')) || 1);
    const [pageSize, setPageSize] = useState(Number(sessionStorage.getItem('currentPageSize')) || 10);

    useEffect(() => {
        isLoaded && setDataSource(orderedProperties(schema, true).map(item => ({...item, key: item.position})));
    }, [isLoaded]);

    const getTableData = () => {
        setIsLoaded(false);

        if (accessList.read) {
            custom_fetch(
                `api/${selectedTableName}/schema/get`, 
                'GET',
                (data) => {
                    setSchema(data);
                    setIsLoaded(true);

                    animatedRowApi.start({to: {opacity: 1}});
                },
                () => setIsLoaded(true),
                () => logOut()
            );
        } else {
            setIsLoaded(true)
        }
    };

    useEffect(() => {
        selectedTableName && getTableData();
    }, [selectedTableName]);

    const handleDeleteRow = (content) => {
        const newSchema = JSON.parse(JSON.stringify(schema));

        for (const propertyName in newSchema.properties) {
            const property = newSchema.properties[propertyName];
            
            if (property.position > newSchema.properties[content].position) {
                property.position = property.position - 1;
            };
        };

        delete newSchema.properties[content];
        
        custom_fetch(
            `api/${selectedTableName}/schema/set`, 
            'POST',
            () => getTableData(),
            () => setSchema(schema),
            () => logOut(),
            JSON.stringify({schema: newSchema}),
            true
          );
    };

    const handleAddEditRow = (newSchema, isChangingPos, handleClose) => {
        custom_fetch(
            `api/${selectedTableName}/schema/set`, 
            'POST',
            () => {
                handleClose();
                
                !isChangingPos && getTableData();
            },
            () => setSchema(schema),
            () => logOut(),
            JSON.stringify({schema: newSchema}),
            true
          );
    };

    const columns = () => {
        const typeDisplay = (item) => {
            if (item['reference']) return 'reference';
            if (item['enum']) return 'enum';
            if (item['format']) return item['format'];
            if (['string', 'number', 'boolean'].includes(item['type'])) return item['type'];
            if (['array'].includes(item['type'])) return <>{item.items['type']}<sup style={{color: '#1677FF'}}>[array]</sup></>
        };
    
        const referenceDisplay = (item) => {
            if (item.reference || item.items?.type === 'reference') {
                return <><Tag key={(item.reference?.table || item.items?.reference.table) + Math.random()}>{(item.reference?.table || item.items?.reference.table)}</Tag>: <Tag key={(item.reference?.key || item.items?.reference?.key) + Math.random()}>{(item.reference?.key || item.items?.reference?.key)}</Tag>: <Tag key={(item.reference?.value || item.items?.reference?.value) + Math.random()}>{(item.reference?.value || item.items?.reference?.value)}</Tag></>
            } else {
                return ''
            }
        };
    
        const display_propertiesDisplay = (item) => {
            if (item || Object.entries(item || {}).length) {
                return Object.entries(item).map(([property, value]) => <Tag key={value + Math.random()}>{`${property}: ${value}`}</Tag>)
            } else {
                return ''
            }
        };

        return [...[
            ...!!accessList.write && journal !== selectedTableName? [{
                key: 'sort',
                align: 'center',
            }]: [],
            {
                width: 100,
                align: 'center',
                render: (text, record, index) => (page - 1) * pageSize + index + 1
            },
            ...['content', 'title', 'description', 'type', 'pattern', 'enum', 'reference', 'display_properties'].map(placeholder => ({
                title: <div className='ant-table-filter-column'><span className='ant-table-column-title'>{t(`table_create.${placeholder}`)}</span></div>,
                width: 200,
                dataIndex: placeholder,
                key: placeholder,
                render: (text, record) => 
                    <p>
                        {(['type'].includes(placeholder) && typeDisplay(record)) ||
                            (['enum'].includes(placeholder) && (record.enum || record.items?.enum)?.map(item => <Tag key={(item) + Math.random()}>{item}</Tag>)) ||
                                (['reference'].includes(placeholder) && referenceDisplay(record)) ||
                                    (['display_properties'].includes(placeholder) && display_propertiesDisplay(text)) || 
                                    ['pattern'].includes(placeholder) && (record.pattern || record.items?.pattern) || text}
                        {['content'].includes(placeholder) &&
                            (schema?.properties[text]?.required && <sup style={{color: '#1677FF'}}>[req]</sup>) ||
                            (schema?.server_side?.includes(text) && <sup style={{color: '#1677FF'}}>[server]</sup>)}
                    </p>
            })),
            {
                title: t('table.actions'),
                dataIndex: 'action',
                key: 'action',
                width: 125,
                fixed: 'right',
                align: 'center',
                render: (text, row) =>
                {
                    const { key: _, ...rowWithoutKey } = row;

                    return <Space size="middle">
                        <CreateEditModal mode={'editing'}
                                         disabled={!schema || journal === selectedTableName || !accessList.write}
                                         shape={'circle'}
                                         tooltip={t(`common.edit`)}
                                         bodyHeight={500}>
                            <SchemaForm  row={{...rowWithoutKey, required: schema?.properties[row['content']]?.required}}
                                         selectedTableName={selectedTableName}
                                         schema={JSON.parse(JSON.stringify(schema))}
                                         tablesList={tablesList.filter(item => item !== journal)}
                                         handleAddEditRow={(newSchema, handleClose) => handleAddEditRow(newSchema, false, handleClose)}/>
                        </CreateEditModal>
                        <CreateEditModal shape='circle' mode={'cloning'} tooltip={t(`common.clone`)} row={row} disabled={row['content'] === schema?.uid || schema?.server_side?.includes(row['content']) || journal === selectedTableName || !accessList.write}>
                                <SchemaForm  uid={schema?.uid}
                                            schema={JSON.parse(JSON.stringify(schema))}
                                            selectedTableName={selectedTableName}
                                            mode={'schema'}
                                            tablesList={tablesList.filter(item => item !== journal)}
                                            row={{...rowWithoutKey}} 
                                            handleAddEditRow={(newSchema, handleClose) => handleAddEditRow(newSchema, false, handleClose)}/>
                            </CreateEditModal>
                        <DeleteModal    handleOk={() => handleDeleteRow(row['content'])}
                                        disabled={row['content'] === schema?.uid || schema?.server_side?.includes(row['content']) || journal === selectedTableName || !accessList.write}
                                        title={t(`common.delete`)}
                                        shape={'circle'}/>
                    </Space>
                }
            }
        ]];
    };

    const components = {
        body: {
            row: Row,
            wrapper: ({...props}) => {
                const children = props.children.flat() || [];

                return (
                    <tbody {...props}>
                        {isLoaded && children.map((child, i) => {
                            return i === 0? child: React.cloneElement(child, {Row: (childProps) => <animated.tr {...childProps} style={animatedRow}/>})
                        })}
                    </tbody>
                )
            },
        }
    };

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setDataSource((previous) => {
                const activeIndex = previous?.findIndex((i) => i.key === active.id);
                const overIndex = previous?.findIndex((i) => i.key === over?.id);
                return arrayMove(previous, activeIndex, overIndex);
            });
            
            const newSchema = () => {
                const newSchema = {...schema};

                for (const propertyName in newSchema.properties) {
                    const property = newSchema.properties[propertyName];

                    if (property.position === active.data.current.sortable.index + 1) {
                        property.position = over.data.current.sortable.index + 1;
                    } else if (active.data.current.sortable.index < over.data.current.sortable.index) {
                        if (property.position > active.data.current.sortable.index + 1 && property.position <= over.data.current.sortable.index + 1) {
                            property.position = property.position - 1;
                        };
                    } else if (active.data.current.sortable.index > over.data.current.sortable.index) {
                        if (property.position < active.data.current.sortable.index + 1 && property.position >= over.data.current.sortable.index + 1) {
                            property.position = property.position + 1;
                        };
                    };
                };

                return newSchema;
            };

            handleAddEditRow(newSchema(), true);
        };
    };

    return (
        <div>
            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext items={isLoaded? (dataSource || []).map(item => item.position): []}
                                 strategy={verticalListSortingStrategy}>
                    <Table columns={!isLoaded? []: columns()}
                        loading={!isLoaded}
                        rowKey="key"
                        pagination={{
                            current: page,
                            pageSize: pageSize,
                            onChange(current, pageSize) {
                                const directionRight = current > page;

                                animatedRowApi.start({
                                    to: {opacity: 0, transform: directionRight? "translateX(-5%)": "translateX(5%)"},
                                    onResolve: () => {
                                        setPage(current);
                                        setPageSize(pageSize);
            
                                        sessionStorage.setItem('currentPage', current);
                                        sessionStorage.setItem('currentPageSize', pageSize);

                                        animatedRowApi.start({from: {opacity: 0, transform: directionRight? "translateX(5%)": "translateX(-5%)"}, to: {opacity: 1, transform: "translateX(0%)"}});
                                    }
                                })
                            }
                        }}
                        components={isLoaded && components}
                        dataSource={!isLoaded? []: dataSource}
                        scroll={{x: 'max-content', y: '71vh'}}
                        bordered />
                </SortableContext>
            </DndContext>
            <div style={{display: 'flex', width: 32, paddingTop: isLoaded && orderedProperties(schema, true).length? 0: 64, position: 'relative', top: -48.5}}>
                <CreateEditModal mode={'adding'}
                                 disabled={!schema || journal === selectedTableName || !accessList.write}
                                 tooltip={t(`common.new`)}
                                 bodyHeight={500}>
                    <SchemaForm  schema={schema}
                                 logOut={logOut}
                                 selectedTableName={selectedTableName}
                                 handleAddEditRow={(newSchema, handleClose) => handleAddEditRow(newSchema, false, handleClose)}
                                 tablesList={tablesList.filter(item => item !== journal)}/>
                </CreateEditModal>
            </div>
        </div>
    )
}

export default SchemaTable;