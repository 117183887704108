import React, {useEffect, useRef, useState} from 'react';
import { Col, Row, Avatar, Button, Input } from 'antd';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { custom_fetch } from '../utils/fetchServices';
import { useSpring, animated } from '@react-spring/web'

const LocalSignIn = ({setUser, logIn}) => {
  const [lockBackgroundColor, setLockBackgroundColor] = useState('#BFBFBF');

  const [pageAnimation, pageAnimationApi] = useSpring(() => ({from: {opacity: 0}, config: {duration: 300}}), []);
  const [lockAnimation, lockAnimationApi] = useSpring(() => ({from: {opacity: 0, scale: 1, marginBottom: 30}, config: {duration: 300}}), []);

  const authRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    pageAnimationApi.start({ from: {opacity: 0}, to: {opacity: 1} });
    lockAnimationApi.start({ from: {opacity: 0}, to: {opacity: 1} });

    authRef.current.focus();
  }, []);
  
  const {t} = useTranslation();

  const [loginFormData, setLoginFormData] = useState({
    login: '',
    password: ''
  });
  const [receivedData, setreceivedData] = useState();

  useEffect(() => {
    if (receivedData) {
      lockAnimationApi.start({
        from: {scale: 0},
        to: {scale: 1},
        onResolve: () => {
          pageAnimationApi.start({ 
            from: {opacity: 1},
            to: {opacity: 0}, 
            onStart: () => {
              setUser(receivedData.login);
            },
            onResolve: () => {
              logIn();
            }
          });
        }
      });
    };
  }, [receivedData]);

  const validate = () => {
    if (loginFormData.login && loginFormData.password) {
      custom_fetch(
        'login', 
        'POST',
        (data) => { 
          lockAnimationApi.start({
            from: {scale: 1},
            to: {scale: 0},
            onResolve: () => {
              setLockBackgroundColor('#87d068');
              setreceivedData(data.user);
            }
          })
        },
        () => {
          lockAnimationApi.start({
            from: {scale: 1},
            to: {scale: 0},
            onResolve: () => {
              setLockBackgroundColor('#FF7875');
              lockAnimationApi.start({from: {scale: 0}, to: {scale: 1}});
            }
          })
        },
        () => {},
        JSON.stringify({user: loginFormData}),
        true,
        false
      );
    };
  };

  // eslint-disable-next-line
  useEffect(() => validate(), []);

  return (
      <Row style={{height: '100vh'}}>
        <Col style={{textAlign: "center", padding: 100}} 
             span={24}>
          <animated.div style={pageAnimation}>
            <animated.div style={lockAnimation}>
              <Avatar style={{backgroundColor: lockBackgroundColor, height: 100, width: 100, padding: 20}}
                      icon={!receivedData? <LockOutlined style={{fontSize: 60}}/>: <UnlockOutlined style={{fontSize: 60}}/>}/>
            </animated.div>
            <form autoComplete={'off'}>
              <div>
                <Input      style={{width: 200}}
                            onKeyDown={event => event.key === "Enter" && passwordRef.current.focus()}
                            ref={authRef}
                            required
                            onChange={e => {
                              const obj = loginFormData;

                              obj.login = e.target.value;

                              setLoginFormData(obj)
                            }}
                            placeholder="User name"
                            margin="normal"
                            variant="outlined"/>
              </div>
              <div>
                <Input      style={{width: 200}}
                            ref={passwordRef}
                            onKeyDown={event => event.key === "Enter" && validate()}
                            required
                            type="password"
                            onChange={e => {
                              const obj = loginFormData;

                              obj.password = e.target.value;

                                setLoginFormData(obj)
                              }}
                              placeholder="Password"
                              margin="normal"
                              variant="outlined"/>
              </div>
              <Button style={{width: 200, marginTop: 30}}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            validate()
                          }}>
                {t('common.login')}
              </Button>
            </form>
          </animated.div>
        </Col>
      </Row>
  )
};

export const OAuthCallback = ({setUser, logIn}) => {
  useEffect(() => {
    custom_fetch(
      `oauth_login${window.location.search}`, 
      'GET',
      (data) => {
        setUser(data.user.login);
    
        logIn();
      },
      () => {},
      () => false
    )
    // eslint-disable-next-line
  });

  return <span>authorizing...</span>;
};

export const AuthorizationForm = ({setUser, logIn, logOut}) => {
  const [oauth_server, setOauth_server] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    custom_fetch(
      'validate_user', 
      'GET',
      (data) => {
        setUser(data.user.login);

        logIn();

        setIsLoaded(true);
      },
      () => {
        custom_fetch(
          'oauth_server', 
          'GET',
          (data) => {
            if (data.oauth_server && data.oauth_server !== 'local') setOauth_server(data.oauth_server);
          
            setIsLoaded(true)
          },
          () => {},
          () => false
        );
      },
      () => false,
      null,
      false,
      false
    );
  }, []);

  return isLoaded && (!oauth_server ? <LocalSignIn setUser={setUser} logIn={logIn}/> : window.location = `${oauth_server}`);
};