// eslint-disable-next-line
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import { Table, Input, Space, Button, Tooltip, Tag, Modal, DatePicker, Dropdown } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { custom_fetch, get_blob } from '../../utils/fetchServices';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import DeleteModal from '../Modals/DeleteModal';
import CreateEditModal from '../Modals/CreateEditModal';
import DataForm from '../Forms/DataForm';
import { useSpring, animated } from '@react-spring/web';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { show_error } from '../../utils/showError';
import { orderedProperties } from '../../utils/orderProperties';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Moscow');

const {RangePicker} = DatePicker;

const DataTable = ({accessList = {}, selectedTableName, journal, logOut, tableDeleted, setTableDeleted}) => {
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const [animatedRow, animatedRowApi] = useSpring(
        () => ({
            from: { opacity: 0, transform: "translateX(0%)" },
            config: {duration: 300}
        }), 
        []
    );
    
    const {t} = useTranslation();

    const [isLoaded, setIsLoaded] = useState(false);
    const [schema, setSchema] = useState();

    const [reference, setReference] = useState();
    
    const [data, setData] = useState();

    const [page, setPage] = useState(Number(sessionStorage.getItem('currentPage')) || 1);
    const [pageSize, setPageSize] = useState(Number(sessionStorage.getItem('currentPageSize')) || 10);
    const [totalPages, setTotalPages] = useState(0);

    const searchInput = useRef(null);

    const [playingEl, setPlayingEl] = useState(null);
    const [audioTimeOut, setAudioTimeOut] = useState(() => {}, 0);
    
    const [file, setFile] = useState();
    const [fileFormat, setFileFormat] = useState();

    const [confirmVisible, setConfirmVisible] = useState(false);
    
    const audioRef = useRef(null);

    const [modalBodyHeight, setModalBodyHeight] = useState(0);

    const initModalBodyHeight = orderedProperties(schema, false)?.filter(([key, prop]) => !schema?.server_side?.includes(key)).length * 150;

    const initGetParams = {page_size: pageSize, page_num: page}

    const [getParams, setGetParams] = useState(initGetParams);

    const [paginationDirectionRight, setPaginationDirectionRight] = useState(true);

    useEffect(() => {
        setGetParams(initGetParams);

        setFilteredInfo({});
        setSortedInfo({});
    }, [selectedTableName])

    useEffect(() => {
        setModalBodyHeight(initModalBodyHeight);

        setPage(Number(sessionStorage.getItem('currentPage')));
    }, [schema]);

    useEffect(() => {
        isLoaded && animatedRowApi.start({to: {opacity: 1}});
    }, [isLoaded]);

    useEffect(() => {
        setGetParams({...getParams, page_num: page, page_size: pageSize});
    }, [page, pageSize]);

    const firstUpdate = useRef(true);

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
    });

    useEffect(() => {
        !firstUpdate.current && getTablePage();
    }, [JSON.stringify(getParams)]);

    const handleFileChange = (e) => {
        if (!!e.target.files?.length) {
            setFile(e.target.files[0]);
            setConfirmVisible(true);
        };
    };
    
    const handleCancelConfirmModal = () => {
        setConfirmVisible(false);
        setFile();
    };

    const handleFileUpload = () => {
        const body = new FormData();

        body.append('file', file);
        body.append('format', fileFormat);
        
        custom_fetch(
            `api/${selectedTableName}/data/load?${new URLSearchParams({fileFormat})}`, 
            'POST',
            () => {
                handleCancelConfirmModal();
                getTableData();
            },
            () => {},
            () => logOut(),
            body,
            true,
            true,
            true
        );
    };

    const getTableData = async () => {
        setIsLoaded(false);

        setSchema();
        setData();
        setReference();

        if (accessList.read) {
            await custom_fetch(
                `api/${selectedTableName}/schema/get`, 
                'GET',
                async (data) => {
                    setSchema({...data});

                    let newReference = reference;

                    for (const [key, prop] of Object.entries(data?.properties)) {
                        if (prop.reference || prop.items?.reference) {
                            await custom_fetch(
                                `api/${(prop.reference || prop.items?.reference).table}/data/get`, 
                                'POST',
                                (data) => {
                                    newReference = ({...newReference, [key]: data.data?.map(item => ({id: item[(prop.reference || prop.items.reference).key], value: item[(prop.reference || prop.items.reference).value]}))});
                                },
                                () => {},
                                () => logOut()
                            );
                        };
                    };
                    
                    setReference(newReference);
                },
                () => {},
                () => logOut()
            );

            await custom_fetch(
                `api/${selectedTableName}/data/get`,
                'POST',
                (data) => {
                    setTotalPages(data.total);
                    setData([...data.data.map((item, index) => ({...item, key: index}))]);
                },
                () => {},
                () => logOut(),
                JSON.stringify(getParams)
            );
            
            setIsLoaded(true);
        } else {
            setSchema();
            setData();
            setReference();

            setIsLoaded(true);
        };
    };

    const getTablePage = () => {
        custom_fetch(
            `api/${selectedTableName}/data/get`, 
            'POST',
            (data) => {
                setData([...data.data.map((item, index) => ({...item, key: index}))]);
                setTotalPages(data.total);
                animatedRowApi.start({from: {opacity: 0, transform: paginationDirectionRight? "translateX(5%)": "translateX(-5%)"}, to: {opacity: 1, transform: "translateX(0%)"}})
            },
            () => {},
            () => logOut(),
            JSON.stringify(getParams)
        );
    };

    const playAudio = () => {
        get_blob(
            `api/${playingEl?.referenceTable}/data/${playingEl?.id}/${playingEl?.referenceValue}/getv`,
            data => {
                const url = URL.createObjectURL(data);

                audioRef.current.src = url;
                audioRef.current.play()
                    .then(() => {
                        clearTimeout(audioTimeOut);
                        
                        const duration = audioRef?.current?.duration;

                        setAudioTimeOut(setTimeout(() => {
                            setPlayingEl(null);
                        }, duration*1000));
                    })
                    .catch(() => {
                        show_error(t('errors.no_audio'));
                        setPlayingEl(null);
                    });
            },
            () => logOut(),
            () => setPlayingEl(null)
        );
    };

    useEffect(() => {
        (selectedTableName || tableDeleted) && getTableData();

        setTableDeleted(false);
    }, [selectedTableName, tableDeleted]);

    useEffect(() => {
        if (playingEl) {
            playAudio();
        };
        // eslint-disable-next-line
    }, [playingEl]);
    
    const onPlayingSet = (id, referenceTable, referenceValue, name, rowId, columnId) => {
        if (id !== playingEl?.id) {
            setPlayingEl({id, referenceTable, referenceValue, name, rowId, columnId});
        } else {
            setPlayingEl(null);

            return audioRef?.current?.pause();
        };
    };
    
    const handleDeleteItem = (itemId) => {
        custom_fetch(
            `api/${selectedTableName}/data/delete`, 
            'POST',
            () => getTableData(),
            () => getTableData(),
            () => logOut(),
            JSON.stringify({uid: itemId}),
            true
          );
    };

    const handleAddEditRow = (data, type, files, handleClose) => {
        custom_fetch(
            `api/${selectedTableName}/data/${type}`, 
            'POST',
            () => {
                for (const key in files) {
                    if (Object.entries(files).length) {
                        const fd = new FormData();

                        fd.append('file', new Blob([files[key].file], {type:"multipart/form-data"}), files[key].name);

                        custom_fetch(
                            `api/${selectedTableName}/data/${data[schema?.uid]}/${key}/setv`, 
                            'POST',
                            () => {},
                            () => {},
                            () => logOut(),
                            fd,
                            false,
                            true,
                            true
                        );
                    };
                };

                handleClose();
                
                getTableData();
            },
            () => {},
            () => logOut(),
            JSON.stringify(data),
            true
          );
    };

    // const addArrLength = (length) => setModalBodyHeight(initModalBodyHeight + (length * 45));
    
    const columns = () => {
        const uid = schema?.uid;

        const getColumnSearchProps = (dataIndex, format) => ({
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, setSearchedColumn}) => (
                <div style={{padding: 8}}>
                    {!['date-time', 'period'].includes(format)?
                        <Input ref={searchInput}
                               placeholder={`${t('common.search')}`}
                               value={selectedKeys[0]}
                               onChange={e => setSelectedKeys(e.target.value ? [e.target.value.toLowerCase()] : [])}
                               onBlur={() => {
                                    if (!Object.keys(getParams.match || {})?.includes(dataIndex)) {
                                        setTimeout(() => setSelectedKeys([]), 1000);
                                    }
                               }}
                               onPressEnter={() => confirm()}
                               style={{width: 188, marginBottom: 8, display: 'block'}}
                        />:
                        <RangePicker style={{ marginBottom: 8, display: 'block' }}
                                     value={selectedKeys[0]} 
                                     onChange={dates => setSelectedKeys(dates ? [dates] : [])} 
                                     onPressEnter={() => { 
                                        confirm(); 
                                     }}
                        />}
                    <Space>
                        <Button type="primary"
                                onClick={() => confirm()}
                                icon={<SearchOutlined/>}
                                size="small"
                                style={{width: 90}}>
                            {t('common.search')}
                        </Button>
                        <Button onClick={() => {
                                            clearFilters();
                                            confirm()}
                                        }        
                                size="small" 
                                style={{width: 90}}>
                            {t('common.reset')}
                        </Button>
                    </Space>
                </div>
            )
        });

        return [
            {
                width: 100,
                align: 'center',
                render: (text, record, index) => (page - 1) * pageSize + index + 1
            },
            ...(orderedProperties(schema, false) || []).filter(([key, prop]) => !prop.display_properties?.table?.includes('hidden')).map(([key, prop]) => {
                const isAudioPlayer = prop.display_properties?.table?.includes('audio-player');

                const width = (isAudioPlayer && 100) || 300;

                const render = (item, row) => {
                    if (isAudioPlayer) return (
                        <Space size="middle">
                            {prop.type !== 'array'? 
                                <Tooltip title={item}>
                                    <Button shape='circle' onClick={() => onPlayingSet(prop.format !== 'data'? item: row[schema.uid], prop.format !== 'data'? prop.reference?.table: selectedTableName, prop.format !== 'data'? prop.reference?.value: key, item, row[schema[uid]], key)}>
                                        {(prop.format !== 'data'? !item || playingEl?.id !== item: playingEl?.name !== item) || playingEl?.columnId !== key || playingEl?.rowId !== row[schema[uid]]? <FontAwesomeIcon icon={faPlay} />: <FontAwesomeIcon icon={faStop} />}
                                    </Button>
                                </Tooltip>
                                :
                                (item || []).map(tag => 
                                    <Tooltip title={tag}>
                                        <Button shape='circle' onClick={() => onPlayingSet(tag, prop.items?.reference?.table, prop.items?.reference?.value, tag, row[schema[uid]], key)}>
                                            {(!tag || playingEl?.id !== tag) || playingEl?.columnId !== key || playingEl?.rowId !== row[schema[uid]]? <FontAwesomeIcon icon={faPlay} />: <FontAwesomeIcon icon={faStop} />}
                                        </Button>
                                    </Tooltip>
                                )
                            }
                        </Space>
                    );

                    if (prop.format === 'date-time') return (
                        item &&
                            <Tag key={item + Math.random()}>
                                {dayjs.unix(Number(item)).format('YYYY-MM-DD HH:mm:ss')}
                            </Tag>
                            || 
                            null
                    );

                    if (prop.type === 'array') {
                        if (prop.format === 'period') return (
                            <span style={{whiteSpace: 'nowrap'}}>
                                {item && item.length && 
                                    <>
                                        <Tag key={item[0] + Math.random()}>
                                            {dayjs.unix(Number(item[0])).format('YYYY-MM-DD HH:mm:ss')}
                                        </Tag>
                                        -&nbsp;&nbsp;
                                        <Tag key={item[1] + Math.random()}>
                                            {dayjs.unix(Number(item[1])).format('YYYY-MM-DD HH:mm:ss')}
                                        </Tag>
                                    </> 
                                    || 
                                    null}
                            </span>
                        );

                        return (
                            <>
                                {(item || []).map((tag, index) => {
                                    return (
                                        <span>
                                            {<Tag key={tag + Math.random()}>{
                                                prop.items?.type === 'date-time' && dayjs.unix(Number(tag)).format('YYYY-MM-DD HH:mm:ss') || tag
                                            }</Tag>}
                                        </span>
                                    )
                                })}
                            </>
                        );
                    }

                    const plainText = (str = '') => str?.includes('${')? str.replaceAll(/\${/g, '<b class="param-text">').replaceAll(/\}/g, '</b>'): str;

                    return (
                        <span dangerouslySetInnerHTML={{__html: 
                                    !Object.keys(getParams.query?.match || {})?.includes(key)?
                                        plainText(item?.toString()):
                                        plainText(item?.toString().replaceAll(new RegExp(getParams.query?.match?.[key]?.toString().slice(0, -1).replace('*', ''), 'gi'), '<mark>$&</mark>'))
                        }}/>
                    )
                };
                
                return {
                    title: prop.title,
                    width: width,
                    dataIndex: key,
                    key: key,
                    render: render,
                    align: isAudioPlayer? 'center': 'left',
                    ...(!isAudioPlayer? getColumnSearchProps(key, prop.format): {}),
                    filteredValue: filteredInfo[key] || null,
                    sortOrder: sortedInfo.columnKey === key ? sortedInfo.order : null,
                    sorter: !prop.display_properties?.table?.includes('{orderable:false}') && !isAudioPlayer? true: false,
                }
            }),
            {
                title: t('table.actions'),
                dataIndex: 'action',
                key: 'action',
                width: 125,
                fixed: 'right',
                align: 'center',
                render: (text, row) =>  
                    {
                        const { key: _, ...rowWithoutKey } = row;

                        return <Space size="middle">
                            <CreateEditModal mode={'editing'}
                                            disabled={!schema || journal === selectedTableName || !accessList.write}
                                            shape={'circle'}
                                            tooltip={t(`common.edit`)}
                                            bodyHeight={modalBodyHeight}>
                                <DataForm   row={{...rowWithoutKey}}
                                            reference={reference}
                                            schema={schema}
                                            handleAddEditRow={handleAddEditRow}/>
                            </CreateEditModal> 
                            <CreateEditModal shape='circle' mode={'cloning'} tooltip={t(`common.clone`)} row={row} disabled={!schema || journal === selectedTableName || !accessList.write}>
                                <DataForm   schema={schema}
                                            reference={reference}
                                            row={{...rowWithoutKey}}
                                            handleAddEditRow={handleAddEditRow}/>
                            </CreateEditModal>
                            <DeleteModal    handleOk={() => handleDeleteItem(row[uid])}
                                            title={t(`common.delete`)}
                                            disabled={journal === selectedTableName || !accessList.write}
                                            shape={'circle'}/>
                        </Space>
                    }
            }
        ]
    };

    const components = {
        body: {
            wrapper: ({...props}) => {
                const children = props.children.flat() || [];

                return (
                    <tbody {...props}>
                        {isLoaded && children.map((child, i) => {
                            return i === 0? child: React.cloneElement(child, {rowComponent: (childProps) => <animated.tr {...childProps} style={animatedRow}/>})
                        })}
                    </tbody>
                )
            }
        }
    };

    return (
        <>
            <audio style={{display: 'none'}} ref={audioRef}/>
            <Table  columns={!isLoaded? []: columns()}
                    loading={!isLoaded}
                    onChange={(pagination, filters, sorter) => {
                        let newGetParams = {...getParams};

                        if (sorter.order) {
                            newGetParams = {...newGetParams, sort: [[sorter.field, sorter.order]]};
                        } else {
                            const {sort, ...rest} = {...newGetParams};

                            newGetParams = {...rest};
                        };

                        if (Object.entries(filters).length) {
                            const newFilters = {};
                            
                            Object.entries(filters).filter(([key, value]) => value?.length).forEach(([key, value]) => {
                                const format = schema.properties[key].format;

                                if (!newFilters['match']) newFilters['match'] = {};
                                if (!newFilters['range']) newFilters['range'] = {}
                                
                                !['date-time', 'period'].includes(format)? newFilters['match'][key] = `*${value[0]}*`: newFilters['range'][key] = {'gte': dayjs(value[0][0]).startOf('day').unix(), lte: dayjs(value[0][1]).endOf('day').unix()};
                            });
                            
                            newGetParams = {...newGetParams, query: {...newFilters}};
                        };

                        setFilteredInfo(filters);
                        setSortedInfo(sorter);

                        setGetParams(newGetParams)
                    }}
                    pagination={{
                        total: totalPages,
                        current: page,
                        pageSize: pageSize,
                        onChange(current, pageSize) {
                            const directionRight = current > page;

                            setPaginationDirectionRight(directionRight);

                            animatedRowApi.start({
                                to: {opacity: 0, transform: directionRight? "translateX(-5%)": "translateX(5%)"},
                                onResolve: () => {
                                    setPage(current);
                                    setPageSize(pageSize);
        
                                    sessionStorage.setItem('currentPage', current);
                                    sessionStorage.setItem('currentPageSize', pageSize);
                                }
                            })
                        }
                    }}
                    dataSource={!isLoaded? []: data}
                    scroll={{x: 'max-content', y: '71vh'}}
                    components={isLoaded && components}
                    bordered />
            <div style={{display: 'flex', justifyContent: 'space-between', width: 74, paddingTop: isLoaded && data?.length? 0: 64, position: 'relative', top: -48.5}}>
                <CreateEditModal mode={'adding'}
                                 disabled={!schema || journal === selectedTableName || !accessList.write || !isLoaded}
                                 tooltip={t(`common.new`)}
                                 bodyHeight={modalBodyHeight}>
                    <DataForm schema={schema}
                              reference={reference}
                              handleAddEditRow={handleAddEditRow}/>
                </CreateEditModal>
                    <div>
                        <Modal  title={t('questions.sure')}
                                width={800}
                                open={confirmVisible}
                                onCancel={handleCancelConfirmModal}
                                destroyOnClose
                                footer={null}>
                            <p> <ExclamationCircleOutlined /> {t(`questions.table_data_will_be_rewrited`)}</p>
                            <div className={'submit-button-block'}>
                            <Button
                                onClick={handleCancelConfirmModal}>
                                {t(`common.cancel`)}
                            </Button>
                            <Button
                                onClick={handleFileUpload}
                                type='primary'>
                                {t(`common.ok`)}
                            </Button>
                            </div>
                        </Modal>
                        <Dropdown   disabled={!schema || journal === selectedTableName || !accessList.write || !isLoaded}
                                    menu={{items: ['json', 'csv'].map(format => ({
                                                key: format,
                                                label: 
                                                    <>
                                                        <input  name={`file_${format}_data`}
                                                                key={`file_${format}_data`}
                                                                id={`file_${format}_data`}
                                                                type="file"
                                                                style={{display: 'none'}}
                                                                onClick={e => e.target.value = null}
                                                                onChange={handleFileChange}
                                                                accept={`.${format}`}/>
                                                            <label style={{cursor: accessList.write? "pointer": "not-allowed"}}>
                                                                {format}
                                                            </label>
                                                    </>
                                            })),
                                           onClick: (item) => {
                                                document.getElementById(`file_${item.key}_data`).click();

                                                setFileFormat(item.key);
                                            }
                                    }}
                                    placement="topLeft">
                            <Button disabled={!schema || journal === selectedTableName || !accessList.write} icon={<UploadOutlined />} />
                        </Dropdown>
                    </div>
            </div>
        </>
    )
}

export default DataTable;