// eslint-disable-next-line
import React, {useEffect, useState} from 'react';
import {Route, Navigate, Routes, Link, useNavigate} from 'react-router-dom';
import { BookOutlined, LogoutOutlined } from '@ant-design/icons';
import { AuthorizationForm, OAuthCallback } from './Components/Auth';
import { ConfigProvider, Badge, Layout, Button, Tooltip, notification, Dropdown } from 'antd';
import { initReactI18next, useTranslation } from 'react-i18next';
import {translation} from './translation';
import { default as pkg } from '../package.json';
import { custom_fetch } from './utils/fetchServices';
import SchemaTable from './Components/Tables/SchemaTable';
import DataTable from './Components/Tables/DataTable';
import i18n from 'i18next';
import ruRU from 'antd/locale/ru_RU';
import enEn from 'antd/locale/en_US';
import { useSpring, animated } from '@react-spring/web';
import ExportFile from './Components/ExportFile';
import DeleteModal from './Components/Modals/DeleteModal';
import NewTableForm from './Components/Forms/NewTableForm';
import CreateEditModal from './Components/Modals/CreateEditModal';
import './App.css';

const {Header, Content, Footer} = Layout;

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {translation: translation.commonEn},
      ru: {translation: translation.commonRu}
    },
    lng: 'ru',
    fallbackLng: 'ru',
    interpolation: { escapeValue: false }
  });

const LangSelect = () => {
    const [iconAnimation, iconAnimationApi] = useSpring(() => ({from: {opacity: 1, transform: "translateY(0%)"}, config: {duration: 300}}), []);

    const [lang, setLang] = useState('ru');

    const changeLang = (val) => {
        if (val !== lang) {
            iconAnimationApi.start({
                from: {opacity: 1, transform: "translateY(0%)"},
                to: {opacity: 0, transform: "translateY(20%)"},
                onResolve: () => {
                    setLang(val);
                    iconAnimationApi.start({ 
                        from: {opacity: 0, transform: "translateY(-20%)"},
                        to: {opacity: 1, transform: "translateY(0%)"}
                  });
                }
              });
        };
    };

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang]);

    const lang_items = [
        {
            key: 'ru',
            label: 'Русский',
        },
        {
            key: 'en',
            label: 'English',
        },
    ];

    return (
        <Dropdown menu={{items: lang_items, selectable: true, defaultSelectedKeys: [lang], onClick: (item) => changeLang(item.key)}}
                  placement="bottomLeft">
            <Button style={{margin: '16px 10px 0 10px'}}>
                <animated.span style={{...iconAnimation, cursor: 'pointer'}}>{lang === 'ru'? 'RU': 'EN'}</animated.span>
            </Button>
        </Dropdown>
    )
};

const App = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);
    
    const {t} = useTranslation();

    const [headerAnimations, headerAnimationsApi] = useSpring(() => ({from: {opacity: 1, y: -64}, config: {duration: 300}}), []);
    const [tableNameAnimation, tableNameAnimationApi] = useSpring(() => ({from: {opacity: 1, transform: "translateY(0%)"}, config: {duration: 300}}), []);
    const [tableAnimations, tableAnimationsApi] = useSpring(() => ({from: {opacity: 0}, config: {duration: 300}}), []);
    const [modeAnimation, modeAnimationApi] = useSpring(() => ({from: {opacity: 1, transform: "translateY(0%)"}, config: {duration: 300}}), []);

    const [api, contextHolder] = notification.useNotification();
    
    const isLoggedIn = () => (sessionStorage.getItem('is_logged_in') === 'true' && true) || false;
    const isSchemaEditMode = () => (sessionStorage.getItem('schema_edit_mode') === 'true' && true) || false;
    
    const [selectedTableName, setSelectedTableName] = useState(window.location.pathname.slice(1));
    const [schemaEditMode, setSchemaEditMode] = useState(isSchemaEditMode);
    const [tablesList, setTablesList] = useState();
    const [journal, setJournal] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [loggedIn, setLoggedIn] = useState(isLoggedIn());

    const [accessList, setAccessList] = useState({});

    const [theme, setTheme] = useState(sessionStorage.getItem('schema_edit_mode') === 'true' && 'dark');

    useEffect(() => {
        if (loggedIn) {
            getAccessList().then(() => getTables());
        };
    }, [loggedIn]);

    useEffect(() => {
        if (isLoaded) {
            headerAnimationsApi.start({to: { opacity: 1, y: 0 }});
            tableAnimationsApi.start({to: { opacity: 1 }});
        };
    }, [isLoaded]);

    const setUser = (user_id) => {
        sessionStorage.setItem('user_id', user_id);
    };

    const logIn = () => {
        sessionStorage.setItem('is_logged_in', true);

        setLoggedIn(true);
    };

    const logOut = () => {
        headerAnimationsApi.start({
            to: {y: -64}, 
            onStart: () => {
                tableAnimationsApi.start({to: { opacity: 0 }});
            },
            onResolve: () => {
                custom_fetch(
                    'oauth_logout',
                    'GET',
                    () => {
                        sessionStorage.setItem('is_logged_in', false);
        
                        setUser();
        
                        setLoggedIn(false);
        
                        window.location = window.location.origin + process.env.PUBLIC_URL + '/log_in';
                    },
                    () => {},
                    () => false
                );
            }
        });

        return true;
    };

    const getAccessList = async () => {
        custom_fetch(
            'api/access', 
            'GET',
            (data) => {
                setAccessList(data?.access)
            },
            () => {},
            () => logOut()
        );
    };

    const getTables = () => {
        custom_fetch(
            'api/config', 
            'GET',
            (data) => {
                const tablesList = Object.keys(data.tables);
                const journal = data.journal?.name;

                if (!selectedTableName || !tablesList.includes(selectedTableName)) {
                    selectTableHandler(tablesList?.[0]);
                }  else {
                    selectTableHandler(selectedTableName);
                };

                if (journal) {
                    const journalItem = tablesList.find(el => journal === el);
                    const journalItemIdx = tablesList.findIndex(el => journalItem === el);
            
                    tablesList.splice(journalItemIdx, 1);
                    tablesList.push(journalItem);
                };

                setTablesList(tablesList);
                setJournal(journal);
                setIsLoaded(true);
            },
            () => logOut(),
            () => logOut()
        );
    };

    const selectTableHandler = (item) => {
        if (item !== selectedTableName || !isLoaded) {
            tableNameAnimationApi.start({
                to: {opacity: 0, transform: "translateY(50%)"},
                onStart: () => tableAnimationsApi.start({to: { opacity: 0 }}),
                onResolve: () => {
                    setSelectedTableName(item);

                    navigate(`/${item}`);

                    sessionStorage.setItem('currentPage', 1);

                    tableNameAnimationApi.start({
                        from: {opacity: 0, transform: "translateY(-50%)"}, 
                        to: {opacity: 1, transform: "translateY(0%)"}
                    });

                    tableAnimationsApi.start({to: { opacity: 1 }})
                }
            });

            sessionStorage.setItem('currentPage', 1);
        }
    };

    const tableModeSwitch = (tableMode) => {
        if (tableMode !== schemaEditMode) {
            modeAnimationApi.start({
                to: {opacity: 0, transform: "translateY(50%)"},
                onStart: () => {
                    tableAnimationsApi.start({to: { opacity: 0 }});
                },
                onResolve: () => {
                    sessionStorage.setItem('schema_edit_mode', !schemaEditMode);
                    sessionStorage.setItem('currentPage', 1);

                    setSchemaEditMode(tableMode);

                    modeAnimationApi.start({from: {opacity: 0, transform: "translateY(-50%)"}, to: {opacity: 1, transform: "translateY(0%)"}})
                    tableAnimationsApi.start({to: { opacity: 1 }, onResolve: () => setTheme(!tableMode? 'light': 'dark')});
                }
            });
        };
    };
    
    const [tableDeleted, setTableDeleted] = useState(false);

    const deleteTable = () => {
        custom_fetch(
            `api/${selectedTableName}${!schemaEditMode? '/data': ''}/delete`, 
            'GET',
            () => !schemaEditMode? setTableDeleted(true): getTables(),
            () => getTables(),
            () => logOut()
        );
    };

    const schema_dropdown_items = [
        {
            key: 'false',
            label: 'data',
        },
        {
            key: 'true',
            label: 'schema',
        }
    ];

    return (
        <ConfigProvider locale={i18n.language === 'en'? enEn: ruRU}>
            {contextHolder}
            {<Layout>
                {loggedIn &&
                    <animated.div style={headerAnimations}>
                        <Header className='header' 
                                style={{backgroundColor: '#1677FF'}}>
                            <div style={{display: 'flex', minWidth: 440, padding: '12px 0 12px 0'}}>
                                <Dropdown menu={{items: tablesList?.map(tableName => 
                                    ({
                                        key: tableName, 
                                        label: <Link to={tableName}>{tableName}</Link>, 
                                        icon: journal === tableName? <BookOutlined />: null
                                    })) || [], 
                                    selectable: true, 
                                    defaultSelectedKeys: [selectedTableName],
                                    selectedKeys: [selectedTableName],
                                    onClick: (item) => selectTableHandler(item.key)}}
                                            placement="bottomLeft">
                                    <Button size='large' style={{width: 300}}>
                                        <animated.span style={{...tableNameAnimation, fontSize: 15}}>
                                            {selectedTableName}
                                        </animated.span>
                                    </Button>
                                </Dropdown>
                                <CreateEditModal size={'large'}
                                                    bodyHeight={160}
                                                    tooltip={t(`common.new_table`)}
                                                    mode={'adding'}
                                                    disabled={!accessList.data?.write || !accessList.schema?.write}
                                                    buttonStyle={{marginLeft: 10}}>
                                    <NewTableForm logOut={logOut} getTables={getTables} selectTableHandler={selectTableHandler} tablesList={tablesList} tableModeSwitch={() => tableModeSwitch(true)}/>
                                </CreateEditModal>
                                <ExportFile type={!schemaEditMode? 'data': 'schema'}
                                            title={t(`common.${!schemaEditMode? 'download_data': 'download_schema'}`)}
                                            style={{marginRight: 10, marginLeft: 10}}
                                            item={selectedTableName}
                                            logOut={logOut}/>
                                <DeleteModal    handleOk={deleteTable}
                                                question={t(`questions.${!schemaEditMode? t(`delete_data_table`): t(`delete_table`)}`)}
                                                disabled={(!schemaEditMode && !accessList.data?.write) || (schemaEditMode && !accessList.schema?.write) || (selectedTableName === journal)}
                                                title={t(`common.${!schemaEditMode? 'delete_data_table': 'delete_table'}`)}
                                                size={'large'}/>
                            </div>
                            <div style={{display: 'flex', minWidth: 433, justifyContent: 'end'}}>
                                <Dropdown menu={{items: schema_dropdown_items, 
                                                    selectable: true, 
                                                    defaultSelectedKeys: [schemaEditMode.toString()],
                                                    selectedKeys: [schemaEditMode.toString()],
                                                    onClick: (item) => tableModeSwitch((item.key === 'true' && true) || false)}}
                                            placement="bottomLeft">
                                    <Button style={{marginTop: 16, width: 80}}>
                                        <animated.span style={{...modeAnimation}}>
                                            {schemaEditMode? 'schema': 'data'}
                                        </animated.span>
                                    </Button>
                                </Dropdown>
                                <LangSelect />
                                <Tooltip title={t(`common.logout`)}>
                                    <Button className={'header-button-style'}
                                            icon={<LogoutOutlined/>}
                                            onClick={logOut} />
                                </Tooltip>
                                <Badge style={{marginTop: 5, backgroundColor: '#FFFFFF', color: '#1E1E65', borderColor: '#D9D9D9'}} count={pkg.version} />
                            </div>
                        </Header>
                    </animated.div>
                }
                <Content>
                    <Routes>
                        {!loggedIn &&
                            <Route  key={'log_in'}
                                    exact path={'/log_in'}
                                    element={
                                        <AuthorizationForm  setUser={setUser}
                                                            logOut={logOut}
                                                            logIn={logIn}/>
                                    }/>
                        }
                        {!loggedIn && 
                            <Route key='null' 
                                    exact path='/nb_cms_callback'
                                    element={
                                        <OAuthCallback  logIn={logIn} 
                                                        setUser={setUser}/>
                                    }/>
                        }
                        {!loggedIn && window.location.pathname !== '/nb_cms_callback' && 
                            <Route key='null' 
                                    path='/*'
                                    element={<Navigate to={'log_in'}/>}
                            />
                        }
                        {loggedIn && 
                            <Route key='null' 
                                path='/*'
                                element={<Navigate to={tablesList? tablesList.find(tableName => tableName === selectedTableName)? selectedTableName: tablesList[0]: ''}/>}
                            />
                        }
                        {loggedIn && isLoaded && accessList && tablesList?.includes(selectedTableName) && tablesList?.map(item => {
                            return (
                                    <Route  key={item || ''}
                                            path={'/' + item}
                                            element={
                                                <animated.div style={{...tableAnimations, padding: '0 50px', marginTop: '30px', verticalAlign: 'center'}}>
                                                    {schemaEditMode?
                                                        <SchemaTable selectedTableName={selectedTableName}
                                                                    logOut={logOut}
                                                                    journal={journal}
                                                                    tablesList={tablesList}
                                                                    accessList={accessList.schema}/>:
                                                        <DataTable  selectedTableName={selectedTableName}
                                                                    tableDeleted={tableDeleted}
                                                                    setTableDeleted={setTableDeleted}
                                                                    logOut={logOut}
                                                                    journal={journal}
                                                                    accessList={accessList.data}/>
                                                    }
                                                </animated.div>
                                            }/>
                                )
                            })
                        }
                    </Routes>
                </Content>
                <Footer className='footer'>
                    <span align='center' style={{whiteSpace: 'nowrap'}}>
                        {'Copyright © BSS '}
                        {`2020 - ${new Date().getFullYear()}`}
                    </span>
                </Footer>
            </Layout>}
        </ConfigProvider>
    )
};

export default App;